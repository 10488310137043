<template>
  <v-select
    ref="vSelect"
    v-model="selectedItems"
    class="v-select-server w-full"
    :label="label"
    :placeholder="placeholder"
    :multiple="multiple"
    :taggable="localTaggable"
    :createOption="createOption"
    :closeOnSelect="closeOnSelect"
    :dropdown-should-open="dropdownShouldOpen"
    :options="allOptions"
    :clearable="clearable"
    :filterable="false"
    :autoscroll="autoscroll"
    :filter-by="filterBy"
    :loading="loading"
    :selectable="selectable"
    :select-on-tab="selectOnTab"
    :disabled="disabled"
    :append-to-body="appendToBody"
    :clear-search-on-select="!searchOnKeyUp"
    @search="(query) => search = query"
    @input="$emit('input', selectedItems)"
    @search:blur="onSearchBlur"
    @search:focus="$emit('search:focus', $event)"
  >
    <template #spinner="{ loading }">
      <div
        v-if="loading"
        class="vs__spinner">
      </div>
    </template>
    <template
      v-if="header"
      #header>
      <div style="opacity: .8">{{ header }}</div>
    </template>
    <template #list-header >
      <ul v-show="!searchOnKeyUp && (isFilterActive || search.length > 0)">
        <li class="text-center">
          <div class="vue-select-pagination">
            <vs-button
              size="small"
              icon-pack="feather"
              icon="icon-refresh-cw"
              class="mx-1"
              :disabled="!isFilterActive"
              @click="makeSearch(true)">
              {{ $t('$General.ResetSearch') }}
            </vs-button>
            <vs-button
              size="small"
              icon-pack="feather"
              icon="icon-search"
              class="ml-1"
              :disabled="search.length === 0"
              @click="makeSearch()">
              {{ $t('$General.Search') }}
            </vs-button>
          </div>
        </li>
        <vs-divider class="my-1"/>
      </ul>
      <slot name="list-header"></slot>
    </template>
    <template #option="item">
      <slot name="option" :item="item">
        <span
          v-if="optionEmphasis && item[optionEmphasis]"
          :title="item.name">
          {{ `${item.name || ''} (${item[optionEmphasis]})` | truncateEllipsis(70) }}
        </span>
        <span
          v-else
          :title="item.name">
          <strong v-if="item.firstsOption">
            {{ `${item.name || ''}` | truncateEllipsis(70) }}
          </strong>
          <template v-else>
          {{ `${item.name || ''}` | truncateEllipsis(70) }}
          </template>
      </span>
      </slot>
    </template>
    <template #selected-option="item">
      <slot name="selected-option" :item="item">
        <span
          v-if="optionEmphasis && item[optionEmphasis]"
          :title="item.name">
          {{ `${item.name || ''} (${item[optionEmphasis]})` | truncateEllipsis(70) }}
        </span>
          <span
            v-else
            :title="item.name">
          {{ `${item.name || ''}` | truncateEllipsis(70) }}
        </span>
      </slot>
    </template>
    <template #no-options="{}">
      <slot
        name="no-options"
        :search="search">
        {{ $t('$General.NotFound') }}
        <template>
        <span>
          -
          <a
            href="#"
            class="link-plain"
            @click.prevent="$emit('create', $refs.vSelect.search)">
            {{ $t('$General.Create') }}
          </a>
        </span>
        </template>
      </slot>
    </template>
    <template #list-footer>
      <template v-if="hasPrevPage || hasNextPage">
        <vs-divider class="my-1"/>

        <div class="vue-select-pagination">
          <vs-button
            size="small"
            class="mr-1"
            icon-pack="feather"
            icon="icon-chevron-left"
            :disabled="!hasPrevPage"
            @click="prevPage()">
            {{ $t('$General.Prev') }}
          </vs-button>
          <vs-button
            size="small"
            class="ml-1"
            icon-pack="feather"
            icon="icon-chevron-right"
            icon-after
            :disabled="!hasNextPage"
            @click="nextPage()">
            {{ $t('$General.Next') }}
          </vs-button>
        </div>
      </template>

      <template v-if="showCreate">
        <slot name="create-options">
          <vs-divider class="my-1"/>

          <div class="text-center">
            <a
              href="#"
              class="link-plain"
              @click="$emit('create', 'footer-create')">
              {{ createText || $t('$General.Create') }}
            </a>
          </div>
        </slot>
      </template>

      <template v-if="manageRouteName && manageText">
        <vs-divider class="my-1"/>

        <div class="text-center">
          <a
            href="#"
            class="link-plain"
            @click="manageCollection()">
            {{ manageText }}
          </a>
        </div>
      </template>
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';

// mixins
import vSelectServerProps from '@/views/modules/_components/v-select-server/v-select-server-props.mixin';
import commonVSelect from '@/views/modules/_mixins/commonVSelect';

/**
 * Component to select item from server side
 *
 * @module views/modules/components/VSelectServer
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object | Array} selectedItems - items to use in v-model directive
 * @vue-data {Array.<Object>} serverOptions - option to select from server
 * @vue-data {number} countServerOptions - total count of options from server
 * @vue-data {string} search - text to search
 * @vue-data {number} offset - offset to fetch data
 * @vue-data {number} page - current page
 * @vue-data {boolean} isFilterActive - indicate if isFilterActive is active or no
 * @vue-data {boolean} loading - indicate of show or no the loading indicator
 * @vue-computed {boolean} localTaggable - local taggable value
 * @vue-computed {number} countDefaultOptions - total count of default options
 * @vue-computed {Object[]} defaultOptionsFiltered - default options filtered by search
 * @vue-computed {number} countDefaultOptionsFiltered - total count of default options filtered
 * @vue-computed {number} defaultOptionPagesCount - pages that cover the default options
 * @vue-computed {number} defaultOptionsOffsetByPageSize - offset of default options by page size
 * @vue-computed {number} defaultOptionsLastPageFreeSpaces -
 * free spaces in the last page that cover the default options
 * @vue-computed {Object[]} defaultOptionsInCurrentPage - the default options for current page
 * @vue-computed {number} totalCount - total count of options with default and server options
 * @vue-computed {number} totalPagesCount - total number of pages
 * @vue-computed {number} lastPage - last page
 * @vue-computed {number} offsetToAddOrSubtract - offset to add or subtract for current page
 * @vue-computed {number} limit - limit to apply on server fetch
 * @vue-computed {boolean} hasNextPage - indicate if exist a next page or no
 * @vue-computed {boolean} hasPrevPage - indicate if exist a prev page or no
 * @vue-computed {Object} searchDefaultFilters - search default filters to use
 * @vue-computed {Object} searchFiltersWithFilter - search filters with filter value
 * @vue-computed {Object} allSearchFilters - default search filter with search filters
 * @vue-computed {boolean} filter - filter to use in the request
 * @vue-computed {Object[]} allOptions - fetch options + default options
 * @vue-event {void} created - created hook to insert to fetch initial data
 * @vue-event {void} value - watch to update value
 * @vue-event {void} fetchData - fetch data to use as options in the select
 * @vue-event {void} makeSearch - fetch data making a filter
 * @vue-event {void} clearSearch - clear search filter
 * @vue-event {void} nextPage - request the next page
 * @vue-event {void} prevPage - request the prevPage
 * @vue-event {void} manageCollection - called to redirect to manage the collection
 * @vue-event {void} onSearchBlur - called on select search blur
 */
export default {
  name: 'VSelectServer',
  components: {
    vSelect,
  },
  mixins: [commonVSelect, vSelectServerProps],
  data() {
    return {
      selectedItems: this.value,
      serverOptions: [],
      countServerOptions: 0,
      search: '',
      offset: 0,
      page: 0,
      isFilterActive: false,
      loading: false,
      searchTimeout: undefined,
    };
  },
  computed: {
    localTaggable() {
      return this.taggable && !this.isFilterActive;
    },
    countDefaultOptions() {
      return this.defaultOptions.length;
    },
    defaultOptionsFiltered() {
      if (this.countDefaultOptions > 0 && this.isFilterActive) {
        const regex = new RegExp(this.search, 'gi');
        return this.defaultOptions.filter((option) => option.name.match(regex));
      }

      return this.defaultOptions;
    },
    countDefaultOptionsFiltered() {
      return this.defaultOptionsFiltered.length;
    },
    defaultOptionPagesCount() {
      return Math.ceil(this.countDefaultOptionsFiltered / this.paginationPageSize);
    },
    defaultOptionsLastPage() {
      return this.defaultOptionPagesCount - 1;
    },
    defaultOptionsOffsetByPageSize() {
      return this.defaultOptionPagesCount * this.paginationPageSize;
    },
    defaultOptionsLastPageFreeSpaces() {
      return this.defaultOptionsOffsetByPageSize - this.countDefaultOptionsFiltered;
    },
    defaultOptionsInCurrentPage() {
      if (this.defaultOptionsLastPage >= 0) {
        const offset = this.page * this.paginationPageSize;

        if (this.defaultOptionsLastPage > this.page) {
          return this.defaultOptionsFiltered.slice(offset, this.paginationPageSize);
        }

        if (this.defaultOptionsLastPage === this.page) {
          return this.defaultOptionsFiltered.slice(offset, this.countDefaultOptionsFiltered);
        }
      }

      return [];
    },
    totalCount() {
      return this.countServerOptions + this.countDefaultOptionsFiltered;
    },
    totalPagesCount() {
      return Math.ceil(this.totalCount / this.paginationPageSize);
    },
    lastPage() {
      return this.totalPagesCount - 1;
    },
    offsetToAddOrSubtract() {
      if (this.defaultOptionsLastPage >= 0) {
        if (this.defaultOptionsLastPage >= this.page) {
          return 0;
        }

        if (this.page - this.defaultOptionsLastPage === 1) {
          return this.defaultOptionsLastPageFreeSpaces;
        }
      }

      return this.paginationPageSize;
    },
    limit() {
      if (this.defaultOptionsLastPage >= 0) {
        if (this.defaultOptionsLastPage > this.page) {
          return 1;
        }

        if (this.defaultOptionsLastPage === this.page) {
          return this.defaultOptionsLastPageFreeSpaces;
        }
      }

      return this.paginationPageSize;
    },
    hasNextPage() {
      return this.page < this.lastPage;
    },
    hasPrevPage() {
      return this.page > 0;
    },
    searchDefaultFilters() {
      return this.useDefaultSearchFilters
        ? {
          name: {
            filterType: 'text',
            type: 'contains',
            filter: this.search,
          },
        }
        : {};
    },
    searchFiltersWithFilter() {
      const filters = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.searchFilters)) {
        if (this.searchFilters[key]) {
          if (this.searchFilters[key].filterType === this.$enums.AppFilterType.CONDITIONAL) {
            const conditionalFilters = {};

            // eslint-disable-next-line no-restricted-syntax
            for (const [condKey, condVal] of Object.entries(this.searchFilters[key].filter)) {
              conditionalFilters[condKey] = {
                ...condVal,
                filter: this.search,
              };
            }

            filters[key] = {
              ...value,
              filter: conditionalFilters,
            };
          } else {
            filters[key] = {
              ...value,
              filter: this.search,
            };
          }
        }
      }

      return filters;
    },
    allSearchFilters() {
      return {
        ...this.searchDefaultFilters,
        ...this.searchFiltersWithFilter,
      };
    },
    filter() {
      return !this.isFilterActive
        ? this.filterParams
        : {
          ...this.allSearchFilters,
          ...this.filterParams,
        };
    },
    allOptions() {
      return [
        ...this.defaultOptionsInCurrentPage,
        ...this.serverOptions,
      ].slice(0, this.paginationPageSize);
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    value() {
      this.selectedItems = this.value;
    },
    search() {
      if (this.searchOnKeyUp) {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(this.makeSearch, 250);
      }
    },
  },
  methods: {
    async fetchData() {
      const data = await this.fetchFunction({
        sortBy: [{ colId: this.sortByField, sort: this.sortByOrder }],
        filtersMatch: this.filtersMatch,
        filters: this.filter,
        skip: this.offset,
        limit: this.limit,
      });

      this.countServerOptions = data.count;
      this.serverOptions = data.data;
    },
    async makeSearch(reset = false) {
      this.resetPagination();
      this.loading = true;

      if (reset) {
        this.clearSearch();
      }

      this.isFilterActive = !reset;
      await this.fetchData();
      this.loading = false;
    },
    clearSearch() {
      this.$refs.vSelect.search = '';
    },
    async nextPage() {
      this.loading = true;
      this.page += 1;
      this.offset += this.offsetToAddOrSubtract;
      await this.fetchData();
      this.loading = false;
    },
    async prevPage() {
      this.loading = true;
      this.offset -= this.offsetToAddOrSubtract;
      this.page -= 1;
      await this.fetchData();
      this.loading = false;
    },
    resetPagination() {
      this.page = 0;
      this.offset = 0;
    },
    manageCollection() {
      this.$emit('redirect', this.manageRouteName);

      if (!this.manageRedirectOnlyEvent) {
        const $this = this;
        setTimeout(() => {
          $this.$router.push({ name: this.manageRouteName });
        }, 0);
      }
    },
    onSearchBlur($event) {
      this.makeSearch(true);
      this.$emit('blur', $event);
    },
    closeSelect() {
      const { searchEl } = this.$refs.vSelect;

      if (searchEl) {
        searchEl.blur();
      }
    },
  },
};
</script>

<style lang="scss">
.v-select-server {
  .vs__spinner {
    border-left-color: rgba(var(--vs-primary), 1);
  }
}
</style>
