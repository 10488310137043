<template>
  <vx-card :title="title">
    <vx-card
      no-shadow
      no-radius
      card-border>
      <template v-slot:no-body>
        <vs-list>
          <div
            v-for="(item, index) in list"
            :key="index"
            :class="{'cursor-pointer': item.route}"
            @click="onItemClick(item)">
            <vs-list-item
              :icon-pack="item.iconPack || 'feather'"
              :icon="item.icon"
              :title="item.title">
              <vs-chip><strong>{{ item.count }}</strong></vs-chip>
            </vs-list-item>
          </div>

          <vs-list-item
            v-if="showTotal"
            class="mt-base"
            icon-pack="fas"
            icon="fa-equals"
            :title="totalText ? totalText : $t('$General.Total')">
            <vs-chip><strong>{{ totalCount }}</strong></vs-chip>
          </vs-list-item>
        </vs-list>
      </template>
    </vx-card>

    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </vx-card>
</template>

<script>
/**
 * Card count classified list
 *
 * @module views/components/CardCountClassifiedList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} title - card title
 * @vue-prop {Object[]} list - list of items
 * @vue-prop {boolean} showTotal - indicate if show or no the total count
 * @vue-prop {string} totalText - text to show in total item
 * @vue-computed {number} totalCount - total count
 * @vue-event {void} onItemClick - call route on item click
 */
export default {
  name: 'CardCountClassifiedList',
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
      validator(steps) {
        return steps.every((step) => typeof step === 'object'
          && step
          && 'icon' in step
          && 'title' in step
          && 'count' in step);
      },
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalText: {
      type: String,
      required: false,
    },
  },
  computed: {
    totalCount() {
      return this.list.reduce((total, item) => total + item.count, 0);
    },
  },
  methods: {
    onItemClick(item) {
      if (item.route) {
        this.$router.push(item.route).catch();
      }
    },
  },
};
</script>
