/**
 * Provides computed and methods for extend validation capabilities in forms
 * @mixin src/views/modules/mixins/formValidationExtensions
 *
 * @vue-computed {boolean} formWasChanged - indicate if the form was changed
 */
export default {
  computed: {
    formWasChanged() {
      const fields = this.validationFields;
      return Object.values(fields).some((field) => field.changed);
    },
  },
  watch: {
    formWasChanged(val) {
      this.$emit('form-was-changed', val);
    },
  },
};
