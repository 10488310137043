import BaseConstructor from '@/views/modules/_common/base.constructor';
import enums from '@/enums';

import { getPhoneNumberInfo } from '@/util/util.phone-number';

/**
 * Create a new phone number
 * @class PhoneNumberConstructor
 */
export default class PhoneNumberConstructor extends BaseConstructor {
  phoneInternational = '';

  phoneNational = '';

  codeCountry = '';

  phoneNumberInfo = {};

  phoneValidate = { isValid: false };

  constructor(operation = enums.Operation.CREATE, payload = null) {
    super(operation, payload);

    const phoneInternational = payload ? payload.phoneInternational || '' : '';
    this.phoneNumberInfo = getPhoneNumberInfo(phoneInternational) || {};
    this.phoneInternational = operation === enums.Operation.CREATE || !payload ? '' : phoneInternational || '';
    this.phoneNational = operation === enums.Operation.CREATE || !payload ? '' : this.phoneNumberInfo.phoneNational || '';
    this.codeCountry = operation === enums.Operation.CREATE || !payload ? '' : this.phoneNumberInfo.codeCountry || '';
    this.phoneValidate = { isValid: false };
  }

  toCreatePayload() {
    return {
      phoneInternational: this.getPhoneInternationalE164(),
    };
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
    };
  }

  getPhoneInternationalE164() {
    if (this.phoneValidate && this.phoneValidate.number && this.phoneValidate.number.e164) {
      return this.phoneValidate.number.e164;
    }

    if (this.phoneNumberInfo && this.phoneNumberInfo.phoneInternationalSignificant) {
      return this.phoneNumberInfo.phoneInternationalSignificant;
    }

    return '';
  }
}
