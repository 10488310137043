<template>
  <div>
    <gs-statistics-card-count
      :key="contactsCardCountKey"
      :fetch-data-function="fetchCountContacts"
      :title="$tc('$Entities.Contact', 2)"
      icon="UserIcon"
      @count="(count) => contactsCount=count">
      <template v-slot:footer>
        <div class="flex justify-between mt-5">
          <a
            href="#"
            class="link-undecorate"
            @click.prevent="activeModalCreateOrEditContact=true">
            <feather-icon
              icon='PlusCircleIcon'
              svgClasses='h-5 w-5 text-primary'/>
          </a>

          <router-link
            to="/contacts"
            class="link-undecorate">
            <feather-icon
              icon='ArrowRightIcon'
              svgClasses='h-5 w-5 text-primary'/>
          </router-link>
        </div>
      </template>
    </gs-statistics-card-count>

    <vs-popup
      :title="$t('$Modals.CreateModalTitle', { entity: this.$tc('$Entities.Contact') })"
      :active.sync="activeModalCreateOrEditContact">
      <transition name="zoom-fade">
        <contact-list-create-or-edit
          v-if="showCreateOrEditContactComponent"
          :entity="this.$tc('$Entities.Contact')"
          :operation="$enums.Operation.CREATE"
          :all-contact-attributes="allContactAttributes"
          @saved="contactCreated"
          @close="activeModalCreateOrEditContact=false">
        </contact-list-create-or-edit>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GsStatisticsCardCount from '@/views/charts-and-maps/charts/GsStatisticsCardCount.vue';
import ContactListCreateOrEdit from '@/views/modules/contacts/ContactListCreateOrEdit.vue';

/**
 * Contact card count with actions
 *
 * @module views/components/ContactsCardCountActions
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} contactsCardCountKey - contact card count key
 * @vue-data {number} contactsCount - contact count
 * @vue-data {boolean} activeModalCreateOrEditContact - active or deactivate create contact modal
 * @vue-data {boolean} showCreateOrEditContactComponent - show or hide create contact component
 * @vue-data {Object[]} allContactAttributes - all contact attributes
 * @vue-event {void} fetchContactAttributes - fetch contact attributes
 * @vue-event {void} contactCreated - call on contact created
 */
export default {
  name: 'ContactsCardCountActions',
  components: {
    GsStatisticsCardCount,
    ContactListCreateOrEdit,
  },
  data() {
    return {
      contactsCardCountKey: 0,
      contactsCount: 0,
      activeModalCreateOrEditContact: false,
      showCreateOrEditContactComponent: false,
      allContactAttributes: [],
    };
  },
  watch: {
    activeModalCreateOrEditContact(val) {
      if (!val) {
        setTimeout(() => {
          this.showCreateOrEditContactComponent = false;
        }, 500);
      } else {
        this.showCreateOrEditContactComponent = true;
      }
    },
    contactsCount(count) {
      this.$emit('count', count);
    },
  },
  created() {
    this.fetchContactAttributes();
  },
  methods: {
    ...mapActions({
      fetchCountContacts: 'contact/fetchCountContacts',
      fetchAllAttributes: 'attribute/fetchAllAttributes',
    }),
    async fetchContactAttributes() {
      const resp = await this.fetchAllAttributes({});
      this.allContactAttributes = resp.data;
    },
    contactCreated() {
      this.activeModalCreateOrEditContact = false;
      this.contactsCardCountKey += 1;
    },
  },
};
</script>
