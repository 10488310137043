import Vue from 'vue';
import vSelect from 'vue-select';
import commonAgGridCustomFilter from '@/views/modules/_mixins/commonAgGridCustomFilter';
import VSelectServer from '@/views/modules/_components/v-select-server/VSelectServer.vue';

/**
 * Options dropdown filter
 *
 * @module views/modules/contacts/contact/filters/OptionsDropDownFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default Vue.extend({
  name: 'OptionsDropDownFilter',
  template: `
    <div
      :id="id"
      :class="{'p-3': agGridFloatingFilter}"
      :style="{
        'min-width': (agGridFloatingFilter ? '20rem' : 'auto'),
        'max-width': (agGridFloatingFilter ? '25rem' : 'auto'),
      }">
      <v-select
        ref="vSelect"
        v-if="agGridFloatingFilter"
        v-model="condition"
        class="w-full mb-4"
        :options="filterConditionOptions.map(c => c.value)"
        :clearable="false"
        :get-option-label="(option) =>
          filterConditionOptions.find((s) => s.value === option).label"
        :appendToBody="agGridFloatingFilter"
        @search:focus="onFocus('vSelect', $refs.vSelect.$el)">
      </v-select>

      <v-select-server
        ref="vSelectServer"
        v-model="selectedItemsLocal"
        :fetch-function="fetchFunction"
        :disabled="disabled"
        class="w-full"
        label="name"
        :placeholder="placeholder"
        :manage-text="manageText"
        :manage-route-name="manageRouteName"
        :manage-redirect-only-event="manageRedirectOnlyEvent"
        :multiple="true"
        :closeOnSelect="false"
        :filterable="false"
        :appendToBody="agGridFloatingFilter"
        :search-on-key-up="true"
        @redirect="$emit('redirect', $event)"
        @input="$emit('input', selectedItemsLocal)"
        @blur="$emit('blur', $event)"
        @search:focus="onFocus('vSelectServer', $refs.vSelectServer.$refs.vSelect.$el)"
        @create="$emit('create', $event)"
      >
        <template #list-header>
          <ul
            v-if="agGridFloatingFilter">
            <li>
              <div class="flex justify-end">
                <a
                  href="#"
                  @click="resetFilter">
                  {{ $t('$General.Reset') | uppercase }}
                </a>
                <a
                  href="#"
                  class="mx-4"
                  @click="applyFilter">
                  {{ $t('$General.Apply') | uppercase }}
                </a>
              </div>
            </li>
            <vs-divider class="my-1"/>
          </ul>
        </template>
        <template #footer>
          <ul
            v-if="agGridFloatingFilter"
            class="pb-1 mt-4">
            <li>
              <div class="ag-filter-apply-panel py-0">
                <button
                  type="button"
                  class="ag-standard-button ag-filter-apply-panel-button"
                  @click="resetFilter">
                  {{ $t('$General.Reset') }}
                </button>
                <button
                  type="button"
                  class="ag-standard-button ag-filter-apply-panel-button"
                  @click="applyFilter">
                  {{ $t('$General.Apply') }}
                </button>
              </div>
            </li>
          </ul>
        </template>
      </v-select-server>
    </div>`,
  mixins: [commonAgGridCustomFilter],
  components: {
    VSelectServer,
    vSelect,
  },
  props: {
    params: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    filterConditionOptions: {
      type: Array,
      required: true,
      validator(options) {
        return options.every(
          (option) => option && typeof option === 'object'
            && 'label' in option
            && 'value' in option,
        );
      },
    },
    selectedItems: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    fetchFunction: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    manageRouteName: {
      type: String,
      required: false,
      default: '',
    },
    manageText: {
      type: String,
      required: false,
      default: '',
    },
    manageRedirectOnlyEvent: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedItemsLocal: this.selectedItems,
      options: [],
      condition: this.$enums.AppFilterOperation.ONE,
    };
  },
  computed: {
    selectedItemsLocalLength() {
      return this.selectedItemsLocal && Array.isArray(this.selectedItemsLocal)
        ? this.selectedItemsLocal.length
        : 0;
    },
  },
  methods: {
    async resetSearch() {
      await this.$refs.vSelectServer.makeSearch(true);
    },
    applyFilter() {
      if (this.params) {
        this.params.filterChangedCallback();
        this.hideAgPopupFilter();
      }
    },
    resetFilter() {
      if (this.params) {
        this.selectedItemsLocal = [];
        this.condition = this.$enums.AppFilterOperation.ONE;
        this.params.filterChangedCallback();
        this.hideAgPopupFilter();
      }
    },
    isFilterActive() {
      return this.selectedItemsLocalLength > 0;
    },
    getModel() {
      if (this.isFilterActive()) {
        return {
          filterType: this.filterType,
          type: this.condition,
          filter: this.selectedItemsLocal,
        };
      }

      return null;
    },
    setModel(model) {
      if (model) {
        this.selectedItemsLocal = [...model.filter];
        this.condition = model.type;
      } else {
        this.selectedItemsLocal = [];
        this.condition = this.$enums.AppFilterOperation.ONE;
      }

      this.params.filterChangedCallback();
    },
  },
});
