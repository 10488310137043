<template>
  <vx-card :no-shadow="noCard">
    <template v-slot:no-body>
      <div :class="{'p-6': !noCard}">
        <h4 v-if="title">{{ title }}</h4>
        <p
          v-if="subtitle"
          class="mt-3 mb-base">
          {{ subtitle}}
        </p>

        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3">
            <gs-radial-percent-chart
              :percentage="percentCompletedSteps"/>

            <h5
              v-if="chartTitle"
              class="text-center mt-5">
              {{ chartTitle }}
            </h5>

            <p
              v-if="chartSubtitle"
              class="text-grey text-center">
              <small>
                {{ chartSubtitle }}
              </small>
            </p>
          </div>

          <div class="vx-col w-full md:w-2/3 mt-base md:mt-0">
            <vs-list
              v-for="(step, index) in steps"
              :key="index">
              <div
                :class="{'cursor-pointer': step.route || step.event}"
                @click="onStepClick(step)">
                <vs-list-item
                  :title="step.title"
                  :subtitle="step.subtitle">
                  <template slot="avatar">
                    <vs-avatar
                      class="mr-4"
                      :color="step.completed ? 'success' : undefined"
                      :icon-pack="step.iconPack || 'feather'"
                      :icon="step.completed ? 'icon-check' : step.icon"/>
                  </template>
                </vs-list-item>
              </div>

            </vs-list>
          </div>
        </div>
      </div>
    </template>
  </vx-card>
</template>

<script>
import GsRadialPercentChart from '@/views/charts-and-maps/charts/GsRadialPercentChart.vue';

/**
 * Complete activation steps
 *
 * @module views/components/CompleteActivationSteps
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} title - title to show
 * @vue-prop {string} subtitle - subtitle to show
 * @vue-prop {string} chartTitle - chart title to show
 * @vue-prop {string} chartSubtitle - chart subtitle to show
 * @vue-prop {boolean} [noCard=false] - indicate if show card or no
 * @vue-prop {Object[]} steps - steps to complete
 * @vue-computed {number} stepsCount - all steps count
 * @vue-computed {number} completedStepsCount - completed steps count
 * @vue-computed {number} percentCompletedSteps - percentage of completed steps
 * @vue-event {void} onStepClick - redirect to route on step click
 */
export default {
  name: 'CompleteActivationSteps',
  components: {
    GsRadialPercentChart,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    chartTitle: {
      type: String,
      required: false,
      default: '',
    },
    chartSubtitle: {
      type: String,
      required: false,
      default: '',
    },
    steps: {
      type: Array,
      required: true,
      validator(steps) {
        return steps.every((step) => typeof step === 'object'
          && step
          && 'title' in step
          && 'subtitle' in step
          && 'icon' in step
          && 'completed' in step);
      },
    },
    noCard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    stepsCount() {
      return this.steps.length;
    },
    completedStepsCount() {
      return this.steps.filter((step) => step.completed).length;
    },
    percentCompletedSteps() {
      return Math.ceil((this.completedStepsCount * 100) / this.stepsCount);
    },
  },
  methods: {
    onStepClick(step) {
      if (step.route) {
        this.$router.push(step.route).catch();
      } else if (step.event) {
        this.$emit(step.event);
      }
    },
  },
};
</script>
