<template>
  <div>
    <div
      v-for="(review, index) in reviews"
      :key="index"
      class="flex"
      :class="{
        'items-center': minimal,
        'mt-base': index > 0 && !minimal,
        'mt-5': index > 0 && minimal,
      }">
      <div
        :class="{'flex items-center': minimal}">
        <a
          v-if="review.avatarRedirectUrl"
          :href="review.avatarRedirectUrl"
          target="_blank">
          <vs-avatar
            :text="review.author"
            :src="review.avatar"/>
        </a>
        <vs-avatar
          v-else
          :text="review.author"
          :src="review.avatar"/>
      </div>

      <div
        class="ml-2 w-full"
        :class="{'cursor-pointer': minimal}"
        @click="showReviewDetails(review)">
        <span
          v-if="!minimal"
          class="font-bold">
          {{ review.author }}
        </span>

        <div
          class="flex items-center"
          :class="{'justify-between': minimal}">
          <span
            v-if="minimal"
            class="font-bold">
          {{ review.author }}
          </span>

          <star-rating
            :rating="review.rating"
            :increment="0.01"
            :round-start-rating="false"
            :show-rating="false"
            read-only
            :star-size="minimal ? 20 : 15"/>

          <small
            v-if="!minimal"
            class="text-grey ml-2">
            {{ review.textTimestamp }}
          </small>
        </div>

        <small
          v-if="!minimal">
          {{ review.comment }}
        </small>
      </div>
    </div>

    <div>
      <vs-popup
        :title="$t('$Components.$ReviewsFeedbackList.ReviewDetails')"
        :active.sync="activeModalReviewDetails">
        <transition name="zoom-fade">
          <div v-if="activeModalReviewDetails">
            <div class="flex justify-center">
              <a
                v-if="reviewToShow.avatarRedirectUrl"
                :href="reviewToShow.avatarRedirectUrl"
                target="_blank">
                <vs-avatar
                  size="large"
                  :text="reviewToShow.author"
                  :src="reviewToShow.avatar"/>
              </a>
              <vs-avatar
                v-else
                size="large"
                :text="reviewToShow.author"
                :src="reviewToShow.avatar"/>
            </div>

            <div class="text-center">
          <span class="font-bold text-center">
            {{ reviewToShow.author }}
          </span>
            </div>

            <div class="flex justify-center">
              <star-rating
                :rating="reviewToShow.rating"
                :increment="0.01"
                :round-start-rating="false"
                :show-rating="false"
                read-only
                :star-size="minimal ? 20 : 15"/>
            </div>

            <div class="text-center">
              <small class="text-grey ml-2">
                {{ reviewToShow.textTimestamp }}
              </small>
            </div>

            <div class="mt-base text-center">
              <small>
                {{ reviewToShow.comment }}
              </small>
            </div>
          </div>
        </transition>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

/**
 * Component to show reviews or feedback comments
 *
 * @module views/components/ReviewsFeedbackList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} [minimal=false] - indicate if show full info or minimal info
 * @vue-prop {Object[]} reviews - review or feedback information
 * @vue-data {boolean} activeModalReviewDetails - indicate if show the review details modal or no
 * @vue-data {Object} reviewToShow - review to show in modal details
 * @vue-event {void} showReviewDetails - show the modal review details
 */
export default {
  name: 'ReviewsFeedbackList',
  components: {
    StarRating,
  },
  props: {
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
    reviews: {
      type: Array,
      required: true,
      validator(reviews) {
        return reviews.every(
          (review) => typeof review === 'object'
          && review
          && review.author
          && review.rating
          && review.timestamp
          && review.comment,
        );
      },
    },
  },
  data() {
    return {
      activeModalReviewDetails: false,
      reviewToShow: null,
    };
  },
  methods: {
    showReviewDetails(review) {
      this.reviewToShow = review;
      this.activeModalReviewDetails = true;
    },
  },
};
</script>
