import enums from '@/enums';
import PhoneNumberConstructor from '@/views/modules/_common/phone-number.constructor';

/**
 * Create a new contact
 * @class ContactConstructor
 */
export default class ContactConstructor extends PhoneNumberConstructor {
  firstName = '';

  lastName = '';

  email = '';

  tags = [];

  unsubscribed = false;

  marketingStatus = enums.Contact.MarketingStatus.SUBSCRIBED;

  #attributes = [];

  constructor(attributes = [], operation = enums.Operation.CREATE, payload = null) {
    super(operation, payload);

    this.firstName = operation === enums.Operation.CREATE || !payload ? '' : payload.firstName || '';
    this.lastName = operation === enums.Operation.CREATE || !payload
      ? '' : payload.lastName || '';
    this.email = operation === enums.Operation.CREATE || !payload ? '' : payload.email || '';
    this.tags = operation === enums.Operation.CREATE || !payload ? [] : payload.tags || [];
    this.marketingStatus = operation === enums.Operation.CREATE || !payload
      ? enums.Contact.MarketingStatus.SUBSCRIBED
      : payload.marketingStatus.value || enums.Contact.MarketingStatus.SUBSCRIBED;
    this.#attributes = attributes || [];
  }

  toCreatePayload() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      tags: this.tags.map((g) => g.id),
      unsubscribed: this.unsubscribed,
      ...this.dynamicPayload(),
      ...super.toCreatePayload(),
    };
  }

  dynamicPayload() {
    const payload = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const attr of this.#attributes) {
      if (attr.type === 'date') {
        payload[attr.id] = new Date(this[attr.id]);
      } else {
        payload[attr.id] = this[attr.id];
      }
    }

    return payload;
  }

  toEditPayload() {
    const { unsubscribed, ...createPayload } = this.toCreatePayload();

    return {
      id: this.payload.id,
      marketingStatus: this.marketingStatus,
      ...createPayload,
    };
  }
}
