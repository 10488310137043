import enums from '@/enums';

/**
 * mixins for v select props
 *
 * @module views/modules/components/v-select-server/v-select-server-props.mixin
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object | Array.<Object>} value - value to use in v-model directive
 * @vue-prop {Function} fetchFunction - function to fetch data
 * @vue-prop {string} [header=null] - header of select
 * @vue-prop {string} [label='name'] - label to show in options
 * @vue-prop {number} [paginationPageSize=5] - size of pagination page
 * @vue-prop {boolean} [multiple=false] - indicate if select is multiple or not
 * @vue-prop {string} [placeholder=''] - placeholder to use in select
 * @vue-prop {boolean} [clearable=true] - indicate if select is clearable or no
 * @vue-prop {boolean} [taggable=false] - indicate if select is taggable or no
 * @vue-prop {Function} [createOption=Function] - function to create new option if taggable is true
 * @vue-prop {Function} [selectable=Function] - function to determinate when a option is selectable
 * @vue-prop {boolean} [closeOnSelect=true] - indicate if select closed on select
 * @vue-prop {string} [optionEmphasis=''] - attribute key to show between parenthesis in option
 * @vue-prop {string} [manageRouteName=''] - route to manage resource listed
 * @vue-prop {string} [manageText=''] - text to show on manage link
 * @vue-prop {Function} [filterBy=()=>...] -
 * Callback to determine if the provided option should match the current search text.
 * Used to determine if the option should be displayed.
 * @vue-prop {string} [filtersMatch=ALL] - filter match
 * @vue-prop {string} [filterParams={}] - filter params to use always in fetch request
 * @vue-prop {string} [searchFilters={}] - search filters
 * @vue-prop {boolean} [useDefaultSearchFilters=true] - indicate if use default filter by name
 * @vue-prop {string} [sortByField='createdAt'] - field to sort items
 * @vue-prop {string} [sortByOrder='desc'] - order to sort items
 * @vue-prop {Object[]} [defaultOptions=[]] - default options to show
 * @vue-prop {string} permissionToManage - required permission to manage the collection listed
 * @vue-prop {boolean} manageRedirectOnlyEvent - indicate if manage redirect only trigger the event
 */
export default {
  props: {
    value: {
      required: true,
    },
    fetchFunction: {
      type: Function,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoscroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginationPageSize: {
      type: Number,
      required: false,
      default: 5,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    createOption: {
      type: Function,
      required: false,
      default(newOption) {
        if (typeof this.optionList[0] === 'object') {
          // eslint-disable-next-line no-param-reassign
          newOption = { [this.label]: newOption };
        }

        this.$emit('option:created', newOption);
        return newOption;
      },
    },
    selectable: {
      type: Function,
      required: false,
      default: () => true,
    },
    selectOnTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    dropdownShouldOpen: {
      type: Function,
      required: false,
    },
    optionEmphasis: {
      type: String,
      required: false,
      default: '',
    },
    manageRouteName: {
      type: String,
      required: false,
      default: '',
    },
    manageText: {
      type: String,
      required: false,
      default: '',
    },
    showCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
    createText: {
      type: String,
      required: false,
      default: '',
    },
    filterBy: {
      type: Function,
      default(option, label, search) {
        return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
      },
    },
    filtersMatch: {
      type: String,
      required: false,
      default: enums.AppFilterMathType.ALL,
      validator(value) {
        return Object.values(enums.AppFilterMathType).includes(value);
      },
    },
    filterParams: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    searchFilters: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    useDefaultSearchFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    sortByField: {
      type: String,
      required: false,
      default: 'createdAt',
    },
    sortByOrder: {
      type: String,
      required: false,
      default: enums.AppSortBy.DESC,
      validator(val) {
        return [
          enums.AppSortBy.ASC,
          enums.AppSortBy.DESC,
        ].indexOf(val) !== -1;
      },
    },
    defaultOptions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    permissionToManage: {
      type: String,
      required: false,
      default: '',
    },
    manageRedirectOnlyEvent: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchOnKeyUp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
