<template>
  <div>
    <div
      v-if="reviewsSettingsAreValid"
      class="vx-row mb-base">
      <div
        class="vx-col w-full"
        :class="{'md:w-1/2': !minimal}">
        <vx-card>
          <vue-element-loading
            :active="!placeDetailsFetched"
            color="rgba(var(--vs-primary), 1)"
            spinner="mini-spinner"/>

          <div class="flex items-center">
            <h3 class="mr-3">
              {{ businessName }}
            </h3>

            <feather-icon
              icon='EditIcon'
              class="cursor-pointer"
              svgClasses='h-6 w-6'
              @click="activeModalReviewSettings=true"/>
          </div>

          <div
            class="flex mt-base"
            :class="minimal ? 'justify-between' : 'justify-around'">
            <div>
              <h1 class="text-center text-5xl font-bold">{{ totalReviews }}</h1>
              <h3 class="text-center">
                {{ $tc('$General.Review', 2) | uppercase }}
              </h3>
            </div>

            <div>
              <h1 class="text-center text-5xl font-bold">{{ rating | fixed_number(1) }}</h1>
              <div class="flex justify-center">
                <star-rating
                  :rating="rating"
                  :increment="0.1"
                  :round-start-rating="false"
                  :show-rating="false"
                  read-only
                  :star-size="20"/>
              </div>
            </div>
          </div>

          <template
            v-if="minimal">

            <vs-divider class="my-base"></vs-divider>

            <reviews-list
              minimal
              :reviews="reviewsMapped"/>
          </template>

          <div
            v-if="reviewsSettingsAreValid"
            class="flex jus  items-center mt-base"
            :class="minimal ? 'justify-between' : 'justify-around'">
            <a
              href="#"
              class="flex items-center link-plain"
              @click.prevent="requestReviews()">
              <feather-icon
                icon='SendIcon'
                class="mr-1"
                svgClasses='h-5 w-5'/>
                <span class="link-text">
                {{ $t('$ReviewsSettingsModule.$SetupCompletedTab.RequestReviewButtonText') }}
                </span>
            </a>

            <a
              href="#"
              class="ml-5 flex items-center link-plain"
              v-clipboard:copy="reviewLink"
              v-clipboard:success="onCopyLink"
              v-clipboard:error="onCopyLinkError"
              @click.prevent>
              <span class="link-text">
                {{ $t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkLinkText') }}
              </span>
              <feather-icon
                icon='CopyIcon'
                class="ml-1"
                svgClasses='h-5 w-5'/>
            </a>
          </div>
        </vx-card>
      </div>
      <div
        v-if="!minimal"
        class="vx-col w-full md:w-1/2"
        :class="!minimal ? 'mt-5 md:mt-0' : ''">
        <vx-card>
          <vue-element-loading
            :active="!placeDetailsFetched"
            color="rgba(var(--vs-primary), 1)"
            spinner="bar-fade-scale"/>

          <transition name="zoom-fade">
            <reviews-list :reviews="reviewsMapped"/>
          </transition>

          <transition name="zoom-fade">
            <template
              v-if="placeDetailsFetched"
              v-slot:footer>
              <div class="flex items-center justify-end">
                <a
                  v-if="placeUrl"
                  :href="placeUrl"
                  target="_blank"
                  class="flex items-center font-bold">
                  {{ $t('$General.ViewAll') }}
                  <feather-icon
                    icon='ArrowRightIcon'
                    class="ml-1"
                    svgClasses='h-5 w-5'/>
                </a>
              </div>
            </template>
          </transition>
        </vx-card>
      </div>
    </div>

    <div
      v-else
      :class="{'md:w-1/2': !minimal}">
      <vx-card>
        <div class="text-center greet-user">
          <img
            src="@/assets/img/reviews/google-my-business-logo.svg"
            alt="Google my business"
            class="mx-auto"
            width="auto"
            height="120">

          <h1 class="mt-5">
            {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.SetupGoogleReviews') }}
          </h1>

          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto my-5">
            {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.SetupMissingMsg') }}
          </p>

          <a
            href="#"
            class="link-popup"
            @click.prevent="activeModalReviewSettings=true">
            {{ $t('$ReviewsSettingsModule.$ReviewsSettingsView.SetupNowButtonText') }}
          </a>
        </div>
      </vx-card>
    </div>

    <GmapMap
      v-show="false"
      ref="mapRef"
      :center="{ lat: 30,lng: 0 }">
    </GmapMap>

    <vs-popup
      :title="$t('$Components.$GoogleReviewsSummaryCard.SetupGoogleReviews')"
      fullscreen
      :active.sync="activeModalReviewSettings"
      class="mt-0 mb-0 pt-0 pb-0">
      <transition name="zoom-fade">
        <reviews-settings-create-or-edit
          v-if="activeModalReviewSettings"
          :card-style="false"
          @cancel="activeModalReviewSettings=false"
          @settings-updated="reviewsSettingsUpdated()"
          @request-reviews="requestReviewsFromReviewSettings()"/>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StarRating from 'vue-star-rating';
import { gmapApi } from 'gmap-vue';

// Components
import ReviewsFeedbackList from '@/views/_components/ReviewsFeedbackList.vue';
import ReviewsSettingsCreateOrEdit from '@/views/modules/reviews/ReviewsSettingsCreateOrEdit.vue';

/**
 * Reviews settings create or edit
 *
 * @module views/components/GoogleReviewsSummaryCard
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} minimal - indicate if component must be minimal or not
 * @vue-data {string} businessName - business name to show
 * @vue-data {number} rating - rating name to show
 * @vue-data {number} totalReviews - total number of reviews to show
 * @vue-data {string} placeUrl - place url
 * @vue-data {Object[]} reviews - reviews to show
 * @vue-data {boolean} placeDetailsFetched - indicate of place details was fetched
 * @vue-data {boolean} activeModalReviewSettings - indicate if show reviews settings modal
 * @vue-computed {boolean} reviewsSettingsAreValid - indicate if reviews settings are valid
 * @vue-computed {Object} googleMapsApi - Google maps api
 * @vue-computed {string} reviewLink - google review link
 * @vue-computed {Object[]} reviewsMapped - reviews to show mapped
 * @vue-event {void} fetchData - fetch component data
 * @vue-event {void} reviewsSettingsUpdated - call on reviews settings updated
 * @vue-event {void} getPlaceDetails - get the place details
 * @vue-event {void} requestReviewsFromReviewSettings - on request review from popup settings
 * @vue-event {void} requestReviews - redirect to request more reviews
 * @vue-event {void} onCopyLink - call on copy review link
 * @vue-event {void} onCopyLinkError - call on error copy review link
 */
export default {
  name: 'GoogleReviewsSummaryCard',
  components: {
    StarRating,
    ReviewsList: ReviewsFeedbackList,
    ReviewsSettingsCreateOrEdit,
  },
  props: {
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      businessName: '',
      rating: 0,
      totalReviews: 0,
      placeUrl: '',
      reviews: [],
      placeDetailsFetched: false,
      activeModalReviewSettings: false,
    };
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
      reviewsSettings: 'auth/tenantAccountSettingsReviews',
    }),
    reviewsSettingsAreValid() {
      return this.getTenantFlag('completedReviewsSettings') && this.reviewsSettings.placeId;
    },
    googleMapsApi: gmapApi,
    reviewLink() {
      return `https://search.google.com/local/writereview?placeid=${this.reviewsSettings.placeId}`;
    },
    reviewsMapped() {
      return this.reviews.map((review) => ({
        author: review.author_name,
        avatar: review.profile_photo_url,
        avatarRedirectUrl: review.author_url,
        rating: review.rating,
        timestamp: review.time,
        textTimestamp: review.relative_time_description,
        comment: review.text,
      }));
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      fetchTenantOfUser: 'auth/fetchTenantOfUser',
    }),
    async fetchData() {
      await this.fetchTenantOfUser();

      if (this.reviewsSettingsAreValid) {
        await this.getPlaceDetails(this.reviewsSettings.placeId);
      }
    },
    async reviewsSettingsUpdated() {
      await this.getPlaceDetails(this.reviewsSettings.placeId);
    },
    async getPlaceDetails(placeId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!placeId) {
          reject(new Error('There is no valid place id'));
        }

        const map = await this.$refs.mapRef.$mapPromise;
        const placesService = new this.googleMapsApi.maps.places.PlacesService(map);
        const request = {
          placeId,
        };

        placesService.getDetails(request, (place, status) => {
          if (
            status === this.googleMapsApi.maps.places.PlacesServiceStatus.OK
            && place
            && place.reviews
            && place.user_ratings_total
            && place.rating
          ) {
            this.businessName = place.name;
            this.reviews = place.reviews;
            this.totalReviews = place.user_ratings_total;
            this.rating = place.rating;
            this.placeUrl = place.url;
            this.placeDetailsFetched = true;
            resolve();
          } else {
            reject(new Error('Error to get place details'));
          }
        });
      });
    },
    requestReviewsFromReviewSettings() {
      this.activeModalReviewSettings = false;

      setTimeout(() => {
        this.requestReviews();
      }, 0);
    },
    requestReviews() {
      this.$router.push(`/sms-campaigns/create?messageType=${this.$enums.Campaign.MessageType.TRANSACTIONAL}`);
    },
    onCopyLink() {
      this.$showSuccessActionNotification({
        title: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyTitle'),
        text: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyOkMsg'),
      });
    },
    onCopyLinkError() {
      this.$showGeneralErrorNotification({
        title: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyTitle'),
        text: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyErrorMsg'),
      });
    },
  },
};
</script>
