<template>
  <statistics-card-line
    hideChart
    :icon="icon"
    icon-right
    :color="color"
    :statistic="count"
    :statisticTitle="title">
    <template
      v-if="$slots.footer"
      v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </statistics-card-line>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import enums from '@/enums';

/**
 * GS statistics card count
 *
 * @module views/charts-and-maps/charts/GsStatisticsCardCount
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Function} fetchDataFunction - function to fetch data
 * @vue-prop {string} icon - icon to show
 * @vue-prop {string} title - title to show
 * @vue-prop {string} color - color of icon
 * @vue-data {number} count - count
 * @vue-event {void} getCount - fetch count
 */
export default {
  name: 'GsStatisticsCardCount',
  components: {
    StatisticsCardLine,
  },
  props: {
    fetchDataFunction: {
      type: Function,
      required: true,
    },
    fetchFilters: {
      required: false,
    },
    fetchFiltersMatch: {
      type: String,
      required: false,
      default: enums.AppFilter.FilterMathType.ALL,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  created() {
    this.getCount();
  },
  methods: {
    async getCount() {
      if (this.fetchFilters) {
        this.count = await this.fetchDataFunction({
          filters: this.fetchFilters,
          filtersMatch: this.fetchFiltersMatch,
        }) || 0;
      } else {
        this.count = await this.fetchDataFunction() || 0;
      }

      this.$emit('count', this.count);
    },
  },
};
</script>
