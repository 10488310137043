/**
 * This provides methods used in ag grid custom filters
 * @mixin views/modules/mixins/commonAgGridCustomFilter
 *
 * @vue-prop {string} header - header of select
 *  * @vue-prop {boolean} agGridFloatingFilter -
 * determine if component set min width and height by default
 * @vue-prop {boolean} paginationPageSize - items per page
 * @vue-data {string} itemsOptions - items options on filter
 * @vue-data {string} search - search query
 * @vue-data {string} offset - pagination offset
 * @vue-data {string} limit - limit of pagination
 * @vue-data {Object|null} domObservers - dom mutations observer
 * @vue-computed {Array.<Object>}  filtered - filtered tags
 * @vue-event {void} onFocus - called on focus
 * @vue-event {void} hideAgPopupFilter - hide popup of ag grid filter
 */
export default {
  props: {
    header: {
      type: String,
      required: false,
      default: null,
    },
    agGridFloatingFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    paginationPageSize: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      itemsOptions: [],
      search: '',
      offset: 0,
      limit: this.paginationPageSize,
      domObservers: [],
    };
  },
  computed: {
    filtered() {
      return this.itemsOptions.filter((t) => t.name.includes(this.search));
    },
  },
  beforeDestroy() {
    this.cleanObservers();
  },
  methods: {
    onFocus(key = 'vSelect', $el = this.$refs.vSelect.$el) {
      let observer;

      if (this.agGridFloatingFilter) {
        if (!this.domObservers.find((obs) => obs.key === key)) {
          const $dropDown = $el.querySelector('.vs__dropdown-toggle');
          const dropdownListId = $dropDown.getAttribute('aria-owns');

          observer = new MutationObserver((mutationList) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const mutation of mutationList) {
              // eslint-disable-next-line no-restricted-syntax
              for (const child of mutation.addedNodes) {
                if (child.id === dropdownListId) {
                  child.classList.add('ag-custom-component-popup');
                }
              }
            }
          });

          this.domObservers.push({
            key,
            observer,
          });
        }

        if (observer) {
          observer.observe(document, { childList: true, subtree: true });
        }
      }
    },
    hideAgPopupFilter() {
      document.querySelector('.ag-theme-material.ag-popup').classList.add('hidden');
      this.cleanObservers();
    },
    cleanObservers() {
      if (this.domObservers.length > 0) {
        this.domObservers.forEach((domObserver) => {
          domObserver.observer.disconnect();
        });
      }

      this.domObservers = [];
    },
  },
};
