<template>
  <form
    class="contact-list-create-or-edit-form"
    @submit.prevent
  >
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="model.firstName"
          class="w-full"
          :class="{required: !isView}"
          :disabled="isView"
          :label="$t('$General.FirstName')"
          :name="$t('$General.FirstName')"
          v-validate="'required|max:50'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$General.FirstName'))"
          :danger-text="errors.first($t('$General.FirstName'))"
          val-icon-danger="clear"/>
      </div>

      <div class="vx-col w-full md:w-1/2 mt-6 md:mt-0">
        <vs-input
          v-model="model.lastName"
          class="w-full"
          :disabled="isView"
          :label="`${$t('$General.LastName')}`"
          :name="$t('$General.LastName')"
          v-validate="'max:50'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$General.LastName'))"
          :danger-text="errors.first($t('$General.LastName'))"
          val-icon-danger="clear"/>
      </div>
    </div>

    <div class="vx-row mt-6">
      <div class="vx-col w-full">
        <vs-input
          v-model="model.email"
          type="email"
          class="w-full"
          :disabled="isView"
          :label="$tc('$General.Email')"
          :name="$tc('$General.Email')"
          v-validate="{ rules: { email: true } }"
          data-vv-validate-on="blur|input"
          :danger="errors.has($tc('$General.Email'))"
          :danger-text="errors.first($tc('$General.Email'))"
          val-icon-danger="clear"/>
      </div>
    </div>

    <div class="vx-row mt-6">
      <div class="vx-col w-full">
        <label
          class="vs-input--label"
          :class="{ required: !isView }">
          {{ $tc('$General.Phone') }}
        </label>
        <vue-tel-input
          v-model="model.phoneNational"
          :disabled="isView"
          :disabledFetchingCountry="true"
          :dynamicPlaceholder="false"
          :enabledCountryCode="true"
          :validCharactersOnly="true"
          :preferredCountries="['AU', 'CO']"
          :defaultCountry="model.codeCountry ? model.codeCountry : 'AU'"
          @validate="(payload) => (this.model.phoneValidate = payload)"
          @input="() => (this.phoneInput = true)"
          @blur="() => (this.phoneInput = true)">
        </vue-tel-input>
        <span
          v-show="invalidPhoneNumber"
          class="text-danger">
          {{ $t('$ContactModule.InvalidPhoneNumberMsg') }}
        </span>
        <span
          v-show="requiredPhoneNumber"
          class="text-danger">
          {{ $t('$ContactModule.PhoneNumberRequiredMsg') }}
        </span>
      </div>
    </div>

    <div class="vx-row mt-6">
      <div
        v-if="loadComplete"
        class="vx-col w-full">
        <label class="vs-input--label">{{ $tc('$General.Tag', 2) }}</label>
        <tags-drop-down-filter
          :disabled="isView"
          :ag-grid-floating-filter="false"
          :selected-tags.sync="model.tags"
          :manage-redirect-only-event="true"
          @redirect="$emit('redirect', $event)">
        </tags-drop-down-filter>
      </div>
    </div>

    <template v-if="this.loadComplete">
      <div class="vx-row mt-6"
           v-for="attribute in allContactAttributes"
           :key="attribute.id">
        <div :class="['vx-col', 'w-full']">

          <template v-if="attributeIsInput(attribute.type)">
            <vs-input
              v-if="attribute.type === $enums.Attributes.Type.NUMBER"
              v-model.number="model[attribute.id]"
              class="w-full"
              :disabled="isView"
              :label="attribute.name"
              :name="attribute.name"
              :type="attribute.type"/>
            <vs-input
              v-else
              v-model="model[attribute.id]"
              class="w-full"
              :disabled="isView"
              :label="attribute.name"
              :name="attribute.name"
              :type="attribute.type"/>
          </template>

          <template v-if="attribute.type === $enums.Attributes.Type.BOOL">
            <label class="vs-input--label">{{ attribute.name }}</label>

            <v-select
              v-model="model[attribute.id]"
              :disabled="isView"
              class="w-full"
              :multiple="false"
              :close-on-select="true"
              :options="[true, false]"
              :get-option-label="((option) => [
                $t('$General.Yes'),
                $t('$General.No')
                ][[true, false].indexOf(option)])"
              :clearable="true">
            </v-select>
          </template>

          <template v-if="attribute.type === $enums.Attributes.Type.CATEGORY">
            <label class="vs-input--label">
              {{ attribute.name }}
            </label>
            <v-select
              v-if="attribute.items.length > 9"
              v-model="model[attribute.id]"
              :disabled="isView"
              class="w-full"
              label="name"
              :multiple="true"
              :close-on-select="false"
              :placeholder="$t('$ContactModule.$CreateOrEdit.SelectCategoryPlaceholder')"
              :options="attribute.items"
              :clearable="true">
            </v-select>
            <div
              v-else
              class="vx-row mt-1">
              <div
                v-for="item in attribute.items"
                :key="item.id"
                class="vx-col w-1/2 md:w-1/3 pb-1">
                <div class="vs-component con-vs-checkbox vs-checkbox-primary vs-checkbox-default">
                  <input
                    v-model="model[attribute.id]"
                    :value="item"
                    :disabled="isView"
                    type="checkbox"
                    class="vs-checkbox--input">
                  <span
                    class="checkbox_x vs-checkbox"
                    style="border: 2px solid rgb(180, 180, 180);">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon notranslate
                      icon-scale vs-checkbox--icon material-icons null">
                        check
                      </i>
                    </span>
                  </span>
                  <span class="con-slot-label text-sm">{{ item.name | truncateEllipsis(18) }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <vs-divider></vs-divider>

    <div
      v-if="isCreation || isCloning"
      class="vx-row">
      <div class="vx-col w-full flex">
        <vs-switch
          v-model="model.unsubscribed"
          class="d-inline-block"
        />
        <label class="d-inline-block ml-3">
          {{ $t('CreateContactAsUnsubscribeLabel') }}
        </label>
      </div>

      <contact-opt-in-agreement
        ref="optInAgreement"
        v-model="optInAgreement"
        :plural="false"
        :popoverConditions="true"
        class="vx-col w-full mt-6"/>
    </div>

    <div
      v-if="isView || isEdition"
      class="vx-row">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ $t('$General.Status') }}</label>

        <v-select
          v-model="model.marketingStatus"
          class="w-full"
          :disabled="isView"
          :options="marketingStatusOptions"
          :clearable="false"
          :multiple="false"
          close-on-select
          :getOptionLabel="(option) => $t(`$Enums.Contact.MarketingStatus.${option}`)"
        />
      </div>
    </div>

    <base-form-footer-action-buttons
      :hide-save="isView"
      :actions="mappedActions"
      @action="onAction"
      @save="saveContact()"
      @cancel="$emit('close')">
      <template
        v-if="isView"
        v-slot:cancel>
        {{ $t("$General.Close") }}
      </template>
    </base-form-footer-action-buttons>
  </form>
</template>

<script>
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import vSelect from 'vue-select';
import { mapActions } from 'vuex';

// Constructor
import ContactConstructor from '@/views/modules/contacts/contact.constructor';

// Custom Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';
import TagsDropDownFilter from '@/views/modules/contacts/filters/TagsDropDownFilter';
import ContactOptInAgreement from '@/views/modules/contacts/_components/ContactOptInAgreement.vue';

// Mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

/**
 * Component to create or edit contacts
 *
 * @module views/modules/contacts/contact/ContactListCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Array.<Object>} allContactAttributes - all contact attributes
 * @vue-data {Object} model - contact model to save
 * @vue-data {boolean} phoneInput - indicate if a phone was entered
 * @vue-computed  {boolean} verifyProperties - verify is form to save is valid
 * @vue-computed  {boolean} invalidPhoneNumber -
 * true if the phone number entered is invalid, otherwise false
 * @vue-computed  {boolean} requiredPhoneNumber -
 * true if the phone number is required and wasn't entered, otherwise false
 * @vue-event {void} created - created hook to insert contact attributes
 * @vue-event {boolean} attributeIsInput - Indicate if one attribute must be an input by its type
 * @vue-event {void} insertContactAttributes - insert contact attributes to model
 * @vue-event {void} onAction - emit action event with action on edit
 */
export default {
  name: 'ContactListCreateOrEdit',
  i18n: {
    messages: {
      en: {
        CreateContactAsUnsubscribeLabel: 'Create contact as unsubscribed',
      },
    },
  },
  components: {
    VueTelInput,
    TagsDropDownFilter,
    BaseFormFooterActionButtons,
    vSelect,
    ContactOptInAgreement,
  },
  mixins: [singleCreateOrEdit],
  props: {
    allContactAttributes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      phoneInput: false,
      addItemFunction: this.addContact,
      editItemFunction: this.editContact,
      optInAgreement: false,
      marketingStatusOptions: Object.values(this.$enums.Contact.MarketingStatus),
    };
  },
  computed: {
    verifyProperties() {
      return this.model.firstName !== '' && (this.model.phoneNational !== '' && this.model.phoneValidate.isValid)
        && (!this.isCreation || !this.isCloning || this.optInAgreement);
    },
    invalidPhoneNumber() {
      return this.phoneInput && !this.model.phoneValidate.isValid && this.model.phoneNational !== '';
    },
    requiredPhoneNumber() {
      return this.phoneInput && !this.model.phoneValidate.isValid && this.model.phoneNational === '';
    },
  },
  watch: {
    'model.marketingStatus': function (val, oldVal) {
      if (oldVal === this.$enums.Contact.MarketingStatus.UNSUBSCRIBED
      && val === this.$enums.Contact.MarketingStatus.SUBSCRIBED) {
        this.$showConfirmWarningDialog({
          title: 'Confirm contact subscription',
          text: 'The contact has previously opted out of your campaign list. Do you want to force the re-addition of this number?',
          cancel: () => {
            this.model.marketingStatus = this.$enums.Contact.MarketingStatus.UNSUBSCRIBED;
          },
        });
      }
    },
  },
  created() {
    this.model = new ContactConstructor(
      this.allContactAttributes,
      this.operation,
      this.modelPayload,
    );

    this.insertContactAttributes();

    this.loadComplete = true;
  },
  methods: {
    ...mapActions({
      addContact: 'contact/addContact',
      editContact: 'contact/editContact',
    }),
    async additionalValidateFormToSave() {
      if (this.isCreation || this.isCloning) {
        return this.$refs.optInAgreement.$validator.validate(this.$t('$General.OptInAgreement'));
      }

      return true;
    },
    attributeIsInput(type) {
      return type === this.$enums.Attributes.Type.TEXT
        || type === this.$enums.Attributes.Type.DATE
        || type === this.$enums.Attributes.Type.NUMBER;
    },
    insertContactAttributes() {
      this.allContactAttributes.forEach((attr) => {
        let value;

        if (this.isCreation) {
          value = this.model[attr.id] || undefined;
        } else {
          value = this.modelPayload[attr.id] || undefined;
        }

        switch (attr.type) {
          case this.$enums.Attributes.Type.TEXT:
            this.$set(this.model, attr.id, value !== undefined ? value : '');
            break;
          case this.$enums.Attributes.Type.DATE:
            this.$set(this.model, attr.id, value !== undefined ? moment.utc(value).format('YYYY-MM-DD') : '');
            break;

          case this.$enums.Attributes.Type.NUMBER:
          case this.$enums.Attributes.Type.BOOL:
            this.$set(this.model, attr.id, value !== undefined ? value : null);
            break;

          case this.$enums.Attributes.Type.CATEGORY:
            this.$set(this.model, attr.id, value !== undefined ? value : []);
            break;

          default:
            this.$set(this.model, attr.id, value !== undefined ? value : '');
        }
      });
    },
    async saveContact() {
      this.phoneInput = true;
      await this.save(this.model.toSavePayload());
    },
  },
};

</script>

<style lang="scss">
.contact-list-create-or-edit-form {
  .vue-tel-input {
    .vti__dropdown-list {
      z-index: 500 !important;
    }
  }
}
</style>
