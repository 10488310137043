<template>
  <div>
    <complete-activation-steps
      v-if="!activationCompleted"
      :title="$t('$Pages.$Home.CompleteActivationStepsTitle')"
      :subtitle="$t('$Pages.$Home.CompleteActivationStepsSubtitle')"
      :chart-title="$t('$Pages.$Home.CompleteActivationStepsChartTitle')"
      :steps="activationsStepsMapped"/>

    <div class="vx-row mt-5">
      <div class="vx-col w-full md:w-1/2">
        <contacts-card-count-actions
          class="w-full"
          @count="(count) => contactsCount=count"/>
        <card-count-classified-list-campaigns
          class="mt-5"
          @count="(count) => campaignsCount=count"/>
      </div>
      <div class="vx-col w-full md:w-1/2 mt-5 md:mt-0">
        <google-reviews-summary-card minimal/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CompleteActivationSteps from '@/views/_components/CompleteActivationSteps.vue';
import GoogleReviewsSummaryCard from '@/views/_components/GoogleReviewsSummaryCard.vue';
import ContactsCardCountActions from '@/views/_components/ContactsCardCountActions.vue';
import CardCountClassifiedListCampaigns from '@/views/_components/CardCountClassifiedListSMSCampaigns.vue';

/**
 * Dashboard home
 *
 * @module views/Home
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} contactsCount - contacts count
 * @vue-data {number} campaignsCount - campaigns count
 * @vue-data {Object[]} activationsSteps - activations steps
 * @vue-computed {boolean} activationCompleted - indicate if activation if completed
 * @vue-computed {Object[]} activationsStepsMapped - activations steps mapped
 */
export default {
  name: 'Home',
  components: {
    CompleteActivationSteps,
    GoogleReviewsSummaryCard,
    ContactsCardCountActions,
    CardCountClassifiedListCampaigns,
  },
  data() {
    return {
      contactsCount: 0,
      campaignsCount: 0,
      activationsSteps: [
        {
          title: this.$t('$Pages.$Home.$ActivationSteps.$CompleteAccountDetails.Title'),
          subtitle: this.$t('$Pages.$Home.$ActivationSteps.$CompleteAccountDetails.Subtitle'),
          route: 'account/details',
          icon: 'icon-user-check',
          completed: false,
        },
        {
          title: this.$t('$Pages.$Home.$ActivationSteps.$AddOrImportContacts.Title'),
          subtitle: this.$t('$Pages.$Home.$ActivationSteps.$AddOrImportContacts.Subtitle'),
          route: '/contacts',
          icon: 'icon-users',
          completed: false,
        },
        {
          title: this.$t('$Pages.$Home.$ActivationSteps.$SetUpGoogleReviews.Title'),
          subtitle: this.$t('$Pages.$Home.$ActivationSteps.$SetUpGoogleReviews.Subtitle'),
          route: '/reviews',
          icon: 'icon-star',
          completed: false,
        },
        {
          title: this.$t('$Pages.$Home.$ActivationSteps.$SendOrScheduleCampaign.Title'),
          subtitle: this.$t('$Pages.$Home.$ActivationSteps.$SendOrScheduleCampaign.Subtitle'),
          route: '/sms-campaigns/create',
          icon: 'icon-send',
          completed: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
      reviewsSettings: 'auth/tenantAccountSettingsReviews',
    }),
    activationCompleted() {
      return this.activationsStepsMapped.every((step) => step.completed);
    },
    activationsStepsMapped() {
      const activationsSteps = [...this.activationsSteps];

      activationsSteps[0].completed = this.getTenantFlag('completedAccountDetails');
      activationsSteps[0].route = '';
      activationsSteps[0].event = 'account-details';
      activationsSteps[1].completed = this.contactsCount > 0;
      activationsSteps[2].completed = this.getTenantFlag('completedReviewsSettings');
      activationsSteps[3].completed = this.campaignsCount > 0;

      return activationsSteps;
    },
  },
};
</script>
