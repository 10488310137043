import themeConfig from '../../../../themeConfig';

/**
 * Provide elements to use in v select components
 * @mixin views/modules/mixins/commonVSelect
 *
 * @vue-prop {boolean} minimalistStyle - indicate if the v select must be minimal style
 * @vue-event {void} mounted - hook to apply minimal styles if is necessary
 */
export default {
  props: {
    minimalistStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      domObserver: null,
    };
  },
  beforeDestroy() {
    if (this.domObserver) {
      this.domObserver.disconnect();
    }
  },
  mounted() {
    if (this.minimalistStyle) {
      const $vSelect = this.$refs.vSelect.$el.querySelector('.vs__dropdown-toggle');
      $vSelect.style.border = 'none';
      $vSelect.style.borderBottom = `1px solid ${themeConfig.colors['grey-light']}`;
      $vSelect.style.borderRadius = 'initial';
    }
  },
  methods: {
    applyModalZIndexToDropDown() {
      if (!this.domObserver) {
        const $dropDown = this.$refs.vSelect.$el.querySelector('.vs__dropdown-toggle');
        const dropdownListId = $dropDown.getAttribute('aria-owns');

        this.domObserver = new MutationObserver((mutationList) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const mutation of mutationList) {
            // eslint-disable-next-line no-restricted-syntax
            for (const child of mutation.addedNodes) {
              if (child.id === dropdownListId) {
                child.classList.add('z-index-above-modal');
              }
            }
          }
        });
      }

      this.domObserver.observe(document, { childList: true, subtree: true });
    },
  },
};
