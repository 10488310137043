import { parsePhoneNumber } from 'libphonenumber-js';
import allCountries from '@/assets/utils/all-countries';

/**
 * Get info of international phone number
 *
 * @param {string} internationalPhoneNumber - international phone number
 * @return {Object} phone number info
 */
export function getPhoneNumberInfo(internationalPhoneNumber) {
  try {
    const e164 = internationalPhoneNumber.indexOf('+') < 0
      ? `+${internationalPhoneNumber}`
      : internationalPhoneNumber;

    const phoneNumber = parsePhoneNumber(e164);
    const country = allCountries.find(
      (c) => c.iso2 === phoneNumber.country.toLowerCase()
        || c.dialCode === phoneNumber.countryCallingCode,
    );

    return {
      phoneInternational: phoneNumber.formatInternational(),
      phoneInternationalSignificant: phoneNumber.number,
      phoneNational: phoneNumber.formatNational(),
      phoneSignificant: phoneNumber.nationalNumber,
      dialCode: phoneNumber.countryCallingCode,
      country: country ? country.name || '' : '',
      codeCountry: phoneNumber.country,
    };
  } catch (e) {
    return null;
  }
}
