import Vue from 'vue';
import { mapActions } from 'vuex';
import NameConstructor from '@/views/modules/_common/name.constructor';

import OptionsDropDownFilter from '@/views/modules/contacts/filters/OptionsDropDownFilter';

/**
 * Tags dropdown filter
 *
 * @module views/modules/contacts/contact/filters/TagsDropDownFilter
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default Vue.extend({
  name: 'TagsDropDownFilter',
  template: `
    <options-drop-down-filter
      ref="OptionsDropDownFilter"
      id="tags-drop-down-filter-wrapper"
      :params="agGridParams"
      :selected-items="selectedTags"
      :fetch-function="fetchAllTags"
      :filter-condition-options="filterConditionOptions"
      :placeholder="$t('$ContactModule.SelectTags')"
      :manage-text="$t('$ContactModule.ManageAudienceTags')"
      manage-route-name="tags"
      :manage-redirect-only-event="manageRedirectOnlyEvent"
      :disabled="disabled"
      :filter-type="$enums.AppFilterType.TAGS"
      :ag-grid-floating-filter="agGridFloatingFilter"
      @blur="$emit('blur', $event)"
      @redirect="$emit('redirect', $event)"
      @create="addTag"
      @input="selectedTagsChanged">
    </options-drop-down-filter>
  `,
  components: {
    OptionsDropDownFilter,
  },
  props: {
    selectedTags: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    manageRedirectOnlyEvent: {
      type: Boolean,
      required: false,
      default: false,
    },
    agGridFloatingFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      filterConditionOptions: Object.values(
        this.$enums.AppFilter.FilterType.Tags.Type,
      ).map((condition) => ({
        label: this.$t(`$AppFilters.$Operation.${condition}`),
        value: condition,
      })),
    };
  },
  computed: {
    agGridParams() {
      return this.params || undefined;
    },
  },
  methods: {
    ...mapActions({
      fetchAllTags: 'tag/fetchAllTags',
      addTagToSelectedAudience: 'tag/addTag',
    }),
    async addTag(name) {
      const newTag = new NameConstructor(name);
      await this.addTagToSelectedAudience(newTag);
      await this.$refs.OptionsDropDownFilter.resetSearch();
    },
    selectedTagsChanged(tags) {
      this.$emit('update:selected-tags', tags);
    },
    getModel() {
      return this.$refs.OptionsDropDownFilter.getModel();
    },
    setModel(model) {
      this.$refs.OptionsDropDownFilter.setModel(model);
    },
  },
});
