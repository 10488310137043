import enums from '@/enums';
import formValidationExtensions from '@/views/modules/_mixins/formValidationExtensions';

/**
 * This provides methods used for create or edit on single route modules
 * @mixin views/modules/mixins/singleCreateOrEdit
 *
 * @vue-prop {string} operation - indicate the operation to make ('create', 'edit', 'clone')
 * @vue-prop {Object} [modelPayload] - object payload to edit operation
 * @vue-prop {string} entity - entity name
 * @vue-prop {Object[]} actions - actions to make in create or edit
 * @vue-data {Object} [model=null] - model to create and edit
 * @vue-data {Object} [loadComplete=false] - indicate if component is already loaded
 * @vue-data {Function | null} [fetchItemFunction=null] - function to fetch one item
 * @vue-data {Function | null} [addItemFunction=null] - function to add one item
 * @vue-data {Function | null} [editItemFunction=null] - function to edit one item
 * @vue-computed  {boolean} verifyProperties - verify is properties on model are valid
 * @vue-computed {boolean} validateForm - validate if exist some errors on forms data
 * @vue-computed {boolean} isView - indicate if operation is view
 * @vue-computed {boolean} isEdition - indicate if operation is edition
 * @vue-computed {boolean} isViewOrEdit - indicate if operation is view or edition
 * @vue-computed {boolean} isCreation - indicate if operation is creation
 * @vue-computed {boolean} isCloning - indicate if operation is cloning
 * @vue-computed {string} operationTitle - operation operationTitle
 * @vue-computed {string} operationText - operation operationText
 * @vue-computed {Object[]} mappedActions - active actions to make in create or edit
 * @vue-computed {boolean} additionalModelWasChanged - additional indication on model changed
 * @vue-computed {boolean} modelWasChanged - indicate if the model was changed
 * @vue-event {Promise<Void>} getItem - get an item from id of modelPayload
 * @vue-event {Promise<boolean>} additionalValidateFormToSave - additional validations to save form
 * @vue-event {Promise<boolean>} validateFormToSave -
 * validate the form to save and return the result
 * @vue-event {Promise<Void>} save - save the model payload if is specified or model for default
 * @vue-event {void} showOperationCompletedNotification - show notification
 * @vue-event {void} onAction - emit an action on response to the same action
 */
export default {
  mixins: [formValidationExtensions],
  props: {
    operation: {
      type: String,
      required: true,
      validator(value) {
        return [
          enums.Operation.VIEW,
          enums.Operation.CREATE,
          enums.Operation.EDIT,
          enums.Operation.CLONE].indexOf(value) !== -1;
      },
    },
    modelPayload: {
      type: Object,
      required: false,
    },
    entity: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: false,
      validator(actions) {
        return actions.every(
          (a) => 'name' in a && 'color' in a
            && 'text' in a && 'position' in a && 'icon' in a
            && 'active' in a,
        );
      },
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      model: null,
      loadComplete: false,
      fetchItemFunction: null,
      addItemFunction: null,
      editItemFunction: null,
      focusOnInit: true,
    };
  },
  mounted() {
    if (this.focusOnInit) {
      this.$nextTick(() => {
        const $el = this.$el.querySelector('input:not(.vs__search)');

        if ($el) {
          $el.focus();
        }
      });
    }
  },
  computed: {
    verifyProperties() {
      return true;
    },
    validateForm() {
      return !this.errors.any() && this.verifyProperties;
    },
    isView() {
      return this.operation === this.$enums.Operation.VIEW;
    },
    isEdition() {
      return this.operation === this.$enums.Operation.EDIT;
    },
    isViewOrEdit() {
      return this.isView || this.isEdition;
    },
    isCreation() {
      return this.operation === this.$enums.Operation.CREATE;
    },
    isCloning() {
      return this.operation === this.$enums.Operation.CLONE;
    },
    operationTitle() {
      return this.isCreation || this.isCloning ? 'Creation' : 'Edition';
    },
    operationText() {
      return this.isCreation || this.isCloning ? 'created' : 'edited';
    },
    mappedActions() {
      return this.actions.filter((action) => action.active);
    },
    additionalModelWasChanged() {
      return false;
    },
    modelWasChanged() {
      return this.formWasChanged || this.additionalModelWasChanged;
    },
  },
  watch: {
    operation(val) {
      this.model.operation = val;
    },
    modelWasChanged(val) {
      this.$emit('model-was-changed', val);
    },
  },
  methods: {
    async getItem() {
      this.model = await this.fetchItemFunction(this.modelPayload.id);
    },
    async additionalValidateFormToSave() {
      return true;
    },
    async validateFormToSave() {
      const formIsValid = await this.$validator.validate();
      const additionalValidation = await this.additionalValidateFormToSave();

      if (!formIsValid) {
        const $el = this.$el.querySelector(`[name='${this.$validator.errors.items[0].field}']`);

        if ($el instanceof HTMLInputElement) {
          $el.focus();
        } else {
          $el.scrollIntoView(false);
        }
      }

      return formIsValid && additionalValidation && this.verifyProperties;
    },
    async save(payload = null) {
      const formIsValid = await this.validateFormToSave();

      if (!formIsValid) {
        return null;
      }

      let itemSaved = null;

      this.$vs.loading({ type: 'radius' });
      if (this.isEdition) {
        itemSaved = await this.editItemFunction(payload || this.model);
      } else {
        itemSaved = await this.addItemFunction(payload || this.model);
      }

      this.$vs.loading.close();
      this.$emit('saved', itemSaved);
      this.showOperationCompletedNotification();

      return itemSaved;
    },
    showOperationCompletedNotification() {
      this.$showSuccessActionNotification({
        title: this.$t(`$Notifications.${this.operationTitle}Title`, {
          entity: this.$tc(`$Entities.${this.entity}`),
        }),
        text: this.$t(`$Notifications.${this.operationTitle}Msg`, {
          entity: this.$tc(`$Entities.${this.entity}`),
        }),
      });
    },
    onAction(action) {
      this.$emit('action', action);
    },
  },
};
