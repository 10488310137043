<template>
  <card-count-classified-list
    :title="$tc('$Entities.SMSCampaign', 2)"
    :list="listItemsWithCount"
    show-total>
    <template v-slot:footer>
      <div class="flex justify-center">
        <vs-button
          icon-pack="feather"
          icon="icon-plus"
          @click="$router.push('/sms-campaigns/create')">
          {{ $t('$Components.$CardCountClassifiedListSMSCampaigns.CreateNewCampaign') }}
        </vs-button>
      </div>
    </template>
  </card-count-classified-list>
</template>

<script>
import CardCountClassifiedList from '@/views/_components/CardCountClassifiedList.vue';
import smsCampaignService from '@/api/modules/campaigns/sms-campaign.service';

/**
 * Card count classified list for SMS campaigns
 *
 * @module views/components/CardCountClassifiedListSMSCampaigns
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object[]} listItems - campaign status items with counts
 * @vue-prop {number[]} campaignsCountByStatus - campaign status counts
 * @vue-computed {number} listItemsWithCount - campaign status items with counts updated
 * @vue-event {void} fetchSMSCampaignsCountByStatus - fetch campaigns counts by status
 */
export default {
  name: 'CardCountClassifiedListSMSCampaigns',
  components: {
    CardCountClassifiedList,
  },
  data() {
    return {
      listItems: [
        {
          title: this.$t('$General.Sent'),
          route: '/sms-campaigns/history',
          icon: 'icon-send',
          count: 0,
        },
        {
          title: this.$t('$General.Scheduled'),
          route: '/sms-campaigns/scheduled',
          icon: 'icon-clock',
          count: 0,
        },
        {
          title: this.$tc('$General.Draft', 2),
          route: '/sms-campaigns/drafts',
          icon: 'icon-edit',
          count: 0,
        },
      ],
      campaignsCountByStatus: [0, 0, 0],
    };
  },
  computed: {
    listItemsWithCount() {
      return this.listItems.map((item, index) => ({
        ...item,
        count: this.campaignsCountByStatus[index],
      }));
    },
    totalCampaignsCount() {
      return this.campaignsCountByStatus.reduce((total, count) => total + count, 0);
    },
  },
  watch: {
    totalCampaignsCount(count) {
      this.$emit('count', count);
    },
  },
  created() {
    this.fetchSMSCampaignsCountByStatus();
  },
  methods: {
    async fetchSMSCampaignsCountByStatus() {
      const resp = await smsCampaignService.getCountByStatus();

      if (resp.data) {
        this.campaignsCountByStatus = [
          resp.data.sent || 0,
          resp.data.scheduled || 0,
          resp.data.draft || 0,
        ];
      }
    },
  },
};
</script>
