<template>
  <div class="contact-opt-in-agreement">
    <vs-checkbox
      v-model="optInAgreementLocal"
      @input="onCheckboxInput()"
      :class="{ popoverOptInCheck: popoverConditions }"
      >
      <span
        v-if="!popoverConditions"
        class="popoverConditions-disabled">
        {{ $tc('$ContactModule.$ImportContacts.OptInAgreementConditionsMsg', i18nCount) }}
      </span>
      <span v-else
        class="popoverConditions-enabled">
        {{ $tc('$ContactModule.$ImportContacts.OptInAgreementConditionsPopoverMsg', i18nCount) }}
        <vs-dropdown v-if="popoverConditions"
          vs-custom-content vs-trigger-click
          class="cursor-pointer popover-conditions-button">
          <a class="flex items-center link-popup  ">
            {{ $t('$ContactModule.$ImportContacts.OptInAgreementConditionsPopoverBtn') }}
            <feather-icon
              icon="ChevronDownIcon"
              style="width:1rem; height:1rem"
              class="cursor-pointer"></feather-icon>
          </a>
          <vs-dropdown-menu class="OptIn-Agreement-Conditions">
            <ul
              class="mt-3 pl-12"
              style="list-style-type: disc">
              <li
                v-for="condition in [1, 2, 3]"
                :key="condition"
                class="my-2">
                {{ $tc(
                `$ContactModule.$ImportContacts.OptInAgreementConditions${condition}`,
                i18nCount) }}
              </li>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
      </span>
    </vs-checkbox>
    <vs-input
      class="w-full"
      :value="optInAgreementLocal ? 'foo' : ''"
      type="hidden"
      :name="$t('$General.OptInAgreement')"
      v-validate="'required'"
      data-vv-validate-on="blur|input"
      :danger="errors.has($t('$General.OptInAgreement'))"
      :danger-text="errors.first($t('$General.OptInAgreement'))"/>

    <ul
      class="mt-3 pl-12"
      style="list-style-type: disc"
      v-if="!popoverConditions">
      <li
        v-for="condition in [1, 2, 3]"
        :key="condition"
        class="my-2">
        {{ $tc(`$ContactModule.$ImportContacts.OptInAgreementConditions${condition}`, i18nCount) }}
      </li>
    </ul>

    <small class="mt-3 font-bold italic">
      {{ $t('$ContactModule.$ImportContacts.OptInAgreementConditionsSuspendMsg') }}
      <a
        href="https://help.sendinblue.com/hc/en-us/articles/213405965"
        class="link-contextual"
        target="_blank">
        {{ $t('$ContactModule.$ImportContacts.OptInAgreementLearnMore') }}.
      </a>
    </small>
  </div>
</template>

<script>
/**
 * Component to accept opt-in agreement for import/create contacts
 *
 * @module views/modules/contacts/_components/ContactOptInAgreement
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'ContactOptInAgreement',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    plural: {
      type: Boolean,
      required: true,
    },
    popoverConditions: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      optInAgreementLocal: this.value,
    };
  },
  computed: {
    i18nCount() {
      return this.plural ? 2 : 1;
    },
  },
  watch: {
    value(val) {
      this.optInAgreementLocal = val;
    },
  },
  methods: {
    onCheckboxInput() {
      setTimeout(() => {
        this.$validator.validate(this.$t('$General.OptInAgreement'));
      });
      this.$emit('input', this.optInAgreementLocal);
    },
  },
};
</script>

<style lang="scss" scoped>

.OptIn-Agreement-Conditions {
  margin-top: 0px !important;
  z-index: 60000 !important;
  max-width: 500px;
  white-space: normal;
  width: 100%;

  ul {
    padding-left: 2rem !important;
    padding-right: 0.5rem !important;
  }

  @media (max-width: 768px) {
    max-width: 60vw;
  }

  @media (max-width: 470px) {
    margin-top: 0px !important;
  }
}

.popoverOptInCheck {
  margin-bottom: 0.5rem !important;
  align-items: flex-start;

  .popoverConditions-enabled {
    .popover-conditions-button {
      z-index: 300;
      position: relative;
    }
  }
}

</style>

<style lang="scss">
.contact-opt-in-agreement {
  .checkbox_x.vs-checkbox {
    @media (max-width: 768px) {
      padding: 8px;
      margin-right: 10px;
    }
  }
}
</style>
